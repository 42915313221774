import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { FaApple, FaReact } from 'react-icons/fa';
import { ImAndroid } from 'react-icons/im';
import { SiFlutter, SiSwift } from 'react-icons/si';
import { BsArrowRightCircle } from 'react-icons/bs';

import Layout from '../components/Layout';
import Seo from '../components/SEO';

import Section from '../components/Section';
import Container from '../components/Container';
import SectionHeading from '../components/SectionHeading';
import Slider from '../components/Slider';

import OurSolutions from '../sections/OurSolutions';
import BusinessModel from '../sections/BusinessModel';

const sliderOptions = {
  slidesToShow: 1,
  arrows: false,
  draggable: false,
  adaptiveHeight: true,
  swipeToSlide: false,
  swipe: false,
  infinite: false,
  fade: true,
};

const appStacks = [
  { title: 'Android App Development', icon: <ImAndroid size={40} />, url: '/' },
  {
    title: 'iOS App Development',
    icon: <FaApple size={40} />,
    url: '/services/ios-app-development',
  },
  { title: 'Swift App Development', icon: <SiSwift size={40} />, url: '/' },
  {
    title: 'React Native App Development',
    icon: <FaReact size={40} />,
    url: '/',
  },
  { title: 'Flutter App Development', icon: <SiFlutter size={40} />, url: '/' },
];

const ServicesPage = ({ location }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Layout pageId="services" location={location} footer={true}>
      <Seo title="Our Services" />
      <Section id="hero">
        <Container className="flex-col pb-0 lg:pb-0 xl:pb-0">
          <span className="flex-1 flex items-center justify-center w-full mt-20">
            <div className="flex-1 flex flex-col items-start justify-center">
              <h1 className="ff-secondary fs-55 fs-lg-58 fw-800 m-0">
                Our <span className="text-primary">Services</span>
              </h1>
            </div>
          </span>
        </Container>
      </Section>

      <Section id="mobile-app">
        <Container className="flex-col pt-12 lg:pt-12 xl:pt-12">
          <span
            className="flex-1 flex flex-col items-start justify-start w-full mt-2 border-t pt-24 lg:pt-24 xl:pt-24"
            style={{ borderColor: '#393939' }}
          >
            <SectionHeading className="fw-300">Mobile App</SectionHeading>

            <div className="w-full mt-16 lg:mt-32">
              <div className="tabs">
                <ul className="flex flex-nowrap items-center justify-start tab-list">
                  <li
                    className={`fs-24 fw-700 text-white py-2 px-1 ${
                      activeTab === 0 ? 'active' : ''
                    }`}
                    onClick={() => setActiveTab(0)}
                  >
                    Development
                  </li>
                  <li
                    className={`fs-24 fw-700 text-white py-2 px-1 ${
                      activeTab === 1 ? 'active' : ''
                    }`}
                    onClick={() => setActiveTab(1)}
                  >
                    Design
                  </li>
                </ul>

                <div className="mt-16 tab-panels">
                  <Slider
                    id="mobile-app__content"
                    settings={{ ...sliderOptions }}
                    goto={activeTab}
                  >
                    <div
                      className={`flex flex-row flex-wrap items-center justify-start panel`}
                    >
                      {appStacks.map((item, i) => {
                        return (
                          <span
                            key={`app-stack-${i}`}
                            className="app-stack dark"
                          >
                            <Link
                              to={item.url}
                              className="flex flex-col mb-10 mx-6"
                            >
                              <span className="flex items-center justify-center icon">
                                {item.icon}
                              </span>
                              <span className="title mt-4">{item.title}</span>
                              <span className="mt-8 button">
                                <BsArrowRightCircle size={40} />
                              </span>
                            </Link>
                          </span>
                        );
                      })}
                    </div>

                    <div
                      className={`flex flex-row flex-wrap items-center justify-start panel`}
                    >
                      {appStacks.map((item, i) => {
                        return (
                          <span
                            key={`app-stack-${i}`}
                            className="app-stack dark"
                          >
                            <Link
                              to={item.url}
                              className="flex flex-col mb-10 mx-6"
                            >
                              <span className="flex items-center justify-center icon">
                                {item.icon}
                              </span>
                              <span className="title mt-4">{item.title}</span>
                              <span className="mt-8 button">
                                <BsArrowRightCircle size={40} />
                              </span>
                            </Link>
                          </span>
                        );
                      })}
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </span>
        </Container>
      </Section>

      <Section id="solutions">
        <OurSolutions />
      </Section>

      <Section id="business-model" backgroundColor="#FFFFFF">
        <BusinessModel />
      </Section>
    </Layout>
  );
};

ServicesPage.propTypes = {
  location: PropTypes.object,
};

export default ServicesPage;
